@tailwind base;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "bootstrap/dist/css/bootstrap.min.css";

@import "./App.scss";

@tailwind components;
@tailwind utilities;
.py-8-2 {
    padding-top: 3rem !important;
    padding-bottom: 1rem !important;
}
@layer base {

    text-6xl {
        font-size: 3.55rem !important;
}

  .bigtext-padding {
      padding-left: 25px !important;
  }

}