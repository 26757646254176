:root,
body {
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
body {
  background-color: #f6f7f9 !important;
}



.accordion > .card:not(:last-of-type) {
  border-bottom: 2px !important;
}

[class*=" w-"] {
  flex: none;
}
.filter-green :hover {
  
  filter: invert(48%) sepia(79%) saturate(1476%) hue-rotate(6deg) brightness(118%) contrast(119%);

}

.hover-style:hover {
filter: invert(48%) sepia(79%) saturate(1476%) hue-rotate(6deg) brightness(118%) contrast(119%);
border-radius: 50%;
}
.btn.is-primary {
  @apply bg-primary text-white py-2 px-6;

  &:hover {
    @apply text-white hover:bg-primary-light hover:border-primary-light;
  }

  &:focus {
    @apply focus:ring-4 ring-primary-light;
  }

  // &:active {
  //   @apply active:bg-primary-dark;
  // }

  &.btn-lg {
    @apply py-3 px-10;
  }

  &.is-expanded {
    @apply px-16;
  }
}
.z-custom-40 {
z-index: 40;
}
.form-control {
  background: #f2f4f8;
  border: 1px solid #f2f4f8;

  &::placeholder {
    color: #878787;
    opacity: 1;
  }
}
.MR {
  margin-right: 50px;
}
.rate-card {
  margin-left: 0.95rem;
}
.custom-left {
  margin-left: -20rem;
}
.w-b {
  background-color: rgb(255, 255, 255);
  color: #ffb224;
}

// ===popup====
.tooltip {
  position: relative;
  display: flex !important;
  border-bottom: 1px dotted rgb(255, 255, 255) !important; /* If you want dots under the hoverable text */
  opacity: 10 !important;
}

/* Tooltip text */
.tooltip .tooltiptext {
  // visibility: hidden;
  background-color: black;
  color: #fff;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  display: block;
}
/* Popup style */
.head-text {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
}
.font-bold-card{
  top: 0;
  text-align: center;
  font-size: 32px;
  color: #1092B2;
}
.qustion-img-mobile {
  display: none;
}
.p-card img {
  width: 18px;
  height: 18px;
  margin-left: 7px;
}
.p-card {
  font-size: 1rem;
  padding-top: 6px;
  display: flex;
  cursor: pointer;
}
.card-headnig h4 {
  margin-right: 1.075rem;
}
.card-headnig img {
  cursor: pointer;
  height: 20px;
  width: 16px;
  padding-top: 3px;
}
.card-headnig {
  display: flex;
}
.qustion-img:hover {
  text-decoration: underline;
}
.row-wrapper {
  margin-bottom: 32px;
  margin-top: 54px;
  margin-right: 1%;
  margin-left: 1%;
}
.card-headnig {
  color: #02293e;
  font-weight: bold;
  font-size: 1.075rem;
}
.p-card-link {
  color: #1092b2;
  font-size: 500;
  font-size: 1.2rem;
  margin-top: 6px;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  
}
.qustion-img {
  padding-top: 15px;
  color: #1092b2;
  cursor: pointer;
}
.qustion-img span {
  font-size: 1.1rem;
  font-family: "Montserrat", sans-serif;
  color: #81c1e1;
  margin-left: 1px;
}

.ml-custom-3 {
  margin-left: 12px;
}
.qustion-img img {
  // width: 5%;
  margin-right: 3px;
  margin-top: 2px;
  height: 5%;
}
.steke-model-btn {
  background-color: #1092b2;
  color: white;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 17px;
  padding: 8px 6px;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
}
.steke-sm-btn-none {
  display: block;
}
.fantom-card1 {
  font-size: 2.575rem;
  font-weight: 700;
  color: #1092b2;
}
.fantom-card2 {
  font-size: 2.575rem;
  font-weight: 700;
  color: #fe7d1e;
}
.fantom-card {
  font-size: 1.7rem;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
}
.img-object {
  max-height: 60px;
  margin-right: 16px;
  max-width: 88px;
}
.custom-object {
  padding-top: 20px;
}

.btn-btm {
  background: #ffffff;
  color: #ffb224;
  box-shadow: 0 2px 2px 2px #fafafa;
}
.btn-top {
  top: 50px;
  box-shadow: 0 2px 2px 2px #fafafa;
}
.img-btm {
  height: 30px;
}

.t-p {
  float: left;
  margin-top: 5px;
}
.popup-box {
  // width:auto;
  position: fixed;
  // background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.stake-card-custom {
  background-color: #f8fafd !important;
  padding: 10px;
  height: px;
  width: 250px !important;
  border: 1px solid white;
  border-radius: 10px;
}
.img111{
  height: 60px;
  width: 88px;
}

.box {
  position: relative;
  margin: 0 auto;
  // height: calc(20vw - 3px);
  // max-height: 170vh;
  background: #f8fafd;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 10px 20px #e0e0e0;
  overflow: hidden;
  width: calc(80vw - 43px);
  // animation-delay: 10s;
  // animation-duration: 1000ms;
  // transition: .5s;
 animation-name: slide;
 animation-duration: 0.7s; /* or: Xms */
 animation-iteration-count: 1;
 animation-direction: alternate; /* or: normal */
 animation-timing-function: ease-in; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
 animation-fill-mode: forwards; /* or: backwards, both, none */
 animation-delay: 0s; 
  // -webkit-animation-delay: 5s;
  // animation: slide 0.1s forwards;
  // transition: transform 800ms ease-in;
  // animation-delay: 0s;
  // transform: rotateY(180deg);
  // max-width: calc(100vh - 143px);
}

// @-webkit-keyframes slide {
//   0% { 
//     opacity:0;
//     -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
//     transform: perspective(800px) translateZ(0) rotateY(0) scale(1);
//    }
// }

@keyframes slide {

  0% {
    opacity:0;
    -webkit-transform: perspective(200px) translateZ(0) rotateX(190deg) scale(0);
    // transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
  }
  100% {
    opacity:10;
    
    -webkit-transform: perspective(190px) translateZ(0) rotateX(360deg) scale(1);
    // transform: perspective(400px) translateZ(0) rotateX(180deg) scale(1);
  }
}

// @keyframes slide {

//   100% { 
//     opacity:1;
//     -webkit-transform: perspective(800px) translateZ(0) rotateY(360deg) scale(1);
//     // transform: perspective(400px) translateZ(0) rotateY(180deg) scale(1);
//   }
// }

.ppp {
  margin-top: 15px;
}

.close-icon {
  content: "f00d";
  cursor: pointer;
  position: absolute;
  right: calc(1%);
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  background: #ffffff00;
  width: 30px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px;
  font-size: 20px;
  color: #1092b2;
}
.modal.fade {
  opacity: 1;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  transform: translate(0);
}
.tooltip {
  position: relative;
  border-bottom: 1px dotted black;
}

.tooltiptext {
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1;
  top: -100%;
  left:0;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
#features {
  
}

// ===popup :end===
// ===text-card===
.text-card {
  font-size: 8px;
}
.icon1 {
  float: right;
  font-size: 0.1px;
}
.mobile-footer {
  display: none;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
  .card-headnig img {
    cursor: pointer;
    height: 16px;
    width: 14px;
    margin-top: -5px;
  padding-top: 0px;
}
.card-headnig h4 {
  margin-right: 0.475rem;
}
  .row-wrapper {

     margin-right: 0px; 
          margin-left: -10px; 
}
  .qustion-img-mobile {
    display: block;
  }
  .rate-card {
    margin-left: 0rem;
  }
  .ml-custom-3 {
    margin-left: 0px;
  }
  .box {
    width: 90%;
    height: auto;
  }
  .steke-sm-btn-none {
    display: none;
  }
  .qustion-img {
    display: none !important;
  }
  .card-headnig {
    color: #02293e;
    font-weight: bold;
    font-size: 10px;
    margin-top: 20px;
  }
  .qustion-img {
    display: none;
  }
  .mobile-footer {
    display: block;
  }
  .steke-model-btn-mobile {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
    padding: 6px 6px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    color: white;
    background-color: #1092b2;
  }
  .qustion-img-mobile {
    display: block;
  }
  .qustion-img-mobile span {
    color: #81c1e1;
    text-decoration: underline;
  }
  .qustion-img-mobile img {
    width: 14px;
    height: 12px;
    margin-right: 5px;
    margin-top: 4px;
  }
  .row-wrapper {
    margin-top: 4px;
  }
  .stake-card {
    padding: 12px 12px;
  }
  .fantom-card {
    font-size: 1rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
}
.fantom-card {
  font-size: 1rem;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
}
.fantom-card1 {
  font-size: 1.575rem;
  font-weight: 700;
  color: #1092b2;
}
.fantom-card2 {
  font-size: 1.575rem;
  font-weight: 700;
  color: #fe7d1e;
}
.p-card-link {
  color: #1092b2;
  font-size: 0.7rem;
  margin-top: 6px;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  text-decoration: underline;
}
  .qustion-img-mobile {
    display: block;

    font-size: 12px;
    margin-top: 6px;
  }
  .steke-model-btn {
    background-color: #1092b2;
    color: honeydew;
    width: 43%;
    margin-left: auto;
    margin-right: unset;
    margin-top: 2px;
    padding: 6px 6px;
    border-radius: 0.25rem;
  }
  .row-wrapper {
    margin-top: 2px;
    margin-bottom: 12px;
  }
  .img-object {
    height: 50px;
    margin-right: 3px;
    margin-left: 3px;
}
  .stake-card-custom {
    margin-top: 16px;
  }
}
// @media screen and (min-width: 1500px) { 

// }
@media screen and (max-width: 3900px) and (min-width: 2600px)  {
  .box {
    width: calc(100% - 10px);
    // border: 2px solid red;
}
}
@media screen and (max-width: 2600px) and (min-width: 1900px)  {
  .box {
    width: calc(50vw - 43px);
    // border: 2px solid red;
}
}
@media screen and (max-width: 1900px)  and (min-width: 1600px) {
  .box {
    width: calc(65vw - 43px);
    // border: 2px solid red;
}
}

@media screen and (max-width: 1600px)  and (min-width: 1080px) {
  .box {
    width: calc(65vw - 43px);
    // border: 2px solid red;
}
}
@media screen and (max-width: 1007px)  and (min-width: 641px) {
  .box {
    width: calc(100vw - 43px);
    // border: 2px solid red;
}
.stake-card-custom {
  margin-bottom: 30px;
}
.fantom-card1 {
  font-size: 1.575rem;
}
.fantom-card2 {
  font-size: 1.575rem;
}
.p-card-link {
  color: #1092b2;
  font-size: 0.802rem; 
}
}
@keyframes upanddown {
  from {
    transform: translate(50%, 25%);
  }
  to {
    transform: translate(50%, -25%);
  }
}

.features-transition {
  animation: upanddown 8s ease-in-out alternate both infinite;
}

@keyframes test {
  from {
    transform: translate(8.117px, 69.798px);
  }
  to {
    transform: translate(152.873px, 237.192px);
  }
}

.arrow-transition {
  position: relative;
  display: inline-block;
  animation: test 8s ease-in-out alternate both infinite;
}

.arrow-transition-2 {
  animation-direction: alternate-reverse;
}

@keyframes test {
  from {
    transform: translate(8.117px, 69.798px);
  }
  to {
    transform: translate(152.873px, 237.192px);
  }
}
.mt-24-custom {
  margin-top: 6rem !important;
}
.animate-spin.spin-fan {
  animation-duration: 60s !important;
}
@media (max-width: 1080px) {
  .mt-24-custom {
    margin-top: 2rem !important;
  }
}
